export const NavDetail = {
    dealer: false,
    customer:false,
    po:false,
    do:false,
    refund:false,
    car:false,
    payment:false,
    fid:false
}
