import secureStorage from "./encrypt";
export const API_URL = {
	GETWAY_API: process.env.REACT_APP_GETWAY_API,
	COMMON_GATEWAY_API: process.env.REACT_APP_GETWAY_API,
	REACT_APP_CALLING_TYPE: process.env.REACT_APP_CALLING_TYPE,
};

export const IS_FAVORITE_APP = (API_URL.REACT_APP_CALLING_TYPE === 'fav') ? true : false;
export const IS_MONTHLY_APP = true;

export const IS_TRIIVCO_APP = (API_URL.REACT_APP_CALLING_TYPE === 'triivco') ? true : false;
export const SETTING = {
	CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
	RESEND_OTP_TIME_IN_SECOND: 30,
};
export const MAKER_ROLE_ID = 1;
export const CHECKER_ROLE_ID = 2;
export const APPROVER_ROLE_ID = 4;
export const TL_ROLE_ID = 4;
export const FINANCE_TEAM_ROLE_ID = 5;
export const OPS_TEAM_ROLE_ID = 6;
export const DATE_FORMAT = "dd mmm yyyy, HH:MM";
export const DATE_FORMAT_DMY = "dd mmm yyyy";
export const DATE_FORMAT_INPUT = "yyyy-MM-dd";
export const DATE_FORMAT_YMD = "yyyy-mm-dd";
export const DATE_FORMAT_MDY = "mmm dd, yyyy";
export const DATE_FORMAT_INPUT_TIME = "yyyy-MM-dd h:mm aa";
export const DATE_FORMAT_FULL = "dd mmm yyyy, HH:MM TT";

export const DATE_FORMAT_FILTER = "yyyy-mm-dd";
export const BANK_LIST_ALL = [
	{ value: "BCA", label: "BCA",account_number : '0845253451',outbound_account_number : '0844545556',is_show_outbound : true ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "BCA2", label: "BCA",account_number : '0842335521',outbound_account_number : '0842335521',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "BCA3", label: "BCA",account_number : '0842545895',outbound_account_number : '0842545895',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "Mandiri", label: "Mandiri",account_number : '1170010080802',outbound_account_number : '1170010080802',is_show_outbound : true ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "CIMB-Niaga", label: "CIMB-Niaga",account_number : '800157674000',outbound_account_number : '800157674000',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "Danamon", label: "Danamon",account_number : '3622268310',outbound_account_number : '3622268310',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "sinarmas_bank", label: "Sinarmas Bank",account_number : '0056626115',outbound_account_number : '0056626115',is_show_outbound : false,is_show_outbound_finance : false, is_show_inbound : true },
	{ value: "BCA4", label: "BCA",account_number : '0356810010',outbound_account_number : '0356810010',is_show_outbound : false,is_show_outbound_finance : true, is_show_inbound : true },
    { value: "Mandiri1", label: "Mandiri",account_number : '1170011254976',outbound_account_number : '1170010080802',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : true},
	{ value: "BCA5", label: "BCA",account_number : '0355585200',outbound_account_number : '0355585200',is_show_outbound : true,is_show_outbound_finance : false, is_show_inbound : true },
];
export const BANK_LIST_UCRF = [
	{ value: "BCA4", label: "BCA KCU Wisma Asia", account_number: '0842586796', outbound_account_number: '0842586796', is_show_outbound: true, is_show_outbound_finance: false, is_show_inbound : false },
	{ value: "BCA5", label: "BCA KCU Sudirman", account_number: '0356810010', outbound_account_number: '0356810010', is_show_outbound: false, is_show_outbound_finance: false, is_show_inbound : true },
	// { value: "BCA6", label: "BIRU Konultasi Indonesia", account_number: '0842586800', outbound_account_number: '0842586800', is_show_outbound: false, is_show_outbound_finance: false, is_show_inbound : true },
	{ value: "BCA6", label: "BCA KCU Wisma Asia", account_number: '0842586800', outbound_account_number: '0842586800', is_show_outbound: false, is_show_outbound_finance: false, is_show_inbound : true },
];

export const BANK_LIST_TRIIVCO = [
	{ value: "BCA", label: "BCA",account_number : '0845253451',outbound_account_number : '0844545556',is_show_outbound : true ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "BCA2", label: "BCA",account_number : '0842335521',outbound_account_number : '0842335521',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "BCA3", label: "BCA",account_number : '0842545895',outbound_account_number : '0842545895',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "Mandiri", label: "Mandiri",account_number : '1170010080802',outbound_account_number : '1170010080802',is_show_outbound : true ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "CIMB-Niaga", label: "CIMB-Niaga",account_number : '800157674000',outbound_account_number : '800157674000',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "Danamon", label: "Danamon",account_number : '3622268310',outbound_account_number : '3622268310',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "sinarmas_bank", label: "Sinarmas Bank",account_number : '0056626115',outbound_account_number : '0056626115',is_show_outbound : false,is_show_outbound_finance : false, is_show_inbound : false },
	{ value: "BCA4", label: "BCA",account_number : '0356810010',outbound_account_number : '0356810010',is_show_outbound : false,is_show_outbound_finance : true, is_show_inbound : false },
    { value: "Mandiri1", label: "Mandiri",account_number : '1170011254976',outbound_account_number : '1170010080802',is_show_outbound : false ,is_show_outbound_finance : false, is_show_inbound : false},
	{ value: "BCA5", label: "BCA",account_number : '0355585200',outbound_account_number : '0355585200',is_show_outbound : true,is_show_outbound_finance : false, is_show_inbound : false },
	{ value: "BCA7", label: "PT Prime Mobil",account_number : '0353262936',outbound_account_number : '0353262936',is_show_outbound : true,is_show_outbound_finance : false, is_show_inbound : true },
];
export const BANK_LIST = (IS_FAVORITE_APP) ? BANK_LIST_UCRF : (IS_TRIIVCO_APP) ? BANK_LIST_TRIIVCO : BANK_LIST_ALL;

export const PAYMENT_TYPE = [
	{ value: "disbursal", label: "Disbursal" },
	{ value: "refund", label: "Refund" }
];
export const SPLIT_PAYMENT_TYPE = [
	{ value: "main_dealer", label: "Dealer Payment" },
	{ value: "split_dealer", label: "Split Payment" },
	{ value: "split_dealer2", label: "Split Payment2" },
	{ value: "agent_fee", label: "Agent Fee" },
	{ value: "referral_agent", label: "Referral Agent" },
	{ value: "agent_incentive", label: "Agent Incentive" },
	{ value: "onhold_dealer", label: "Onhold Dealer Payment" },
	{ value: "aro_fee", label: "ARO Fee" },
	{ value: "daily_bonus", label: "Daily Bonus" },
	{ value: "bm_fee", label: "BM Fee" },
	{ value: "bro_fee", label: "BRO Fee" },
	{ value: "onhold_agent", label: "Onhold Agent Payment" },
    { value: "platinum_incentive", label: "Platinum Agent Incentive" },
	{ value: "take_over_amount", label: "Take Over Payment" },
	{ value: "customer_payment", label: "Customer Payment" },
];
export const PAYMENT_ORDER = ['main_dealer', 'split_dealer', 'split_dealer2', 'referral_agent', 'agent_fee','agent_incentive','bm_fee','bro_fee'];
export const IS_NUMBER_VALIDATION = /^[0-9]*$/;

export const REQUEST_PAYMENT_TYPE = {
	"Normal": "Payment will be made to dealer once the amount is received from the Financier",
	"Ad-Hoc": "Payment need to be made to dealer within 1 hour of request generation",
	"Advance": "Payment need to be made to dealer by today only or in the next batch of payment requests",
	"SFAP": "Payment need to be made to dealer as soon as the request gets generated",
}
export const REQUEST_PAYMENT_TYPES = [
	{ value: "normal", label: "Normal" },
	{ value: "ad_hoc", label: "Ad-Hoc" },
	{ value: "advance", label: "Advance" }
]

export const PAYMENT_REQUEST_TYPE = [
	{ value : "Normal",  label : "Normal" },
	{ value : "Ad-Hoc",  label : "Ad Hoc" },
	{ value : "Advance", label : "Advance"},
	{ value : "SFAP", label: "SFAP" }
]
export const displayAmount = (amount) => {
	let amount2display= `IDR ${amount}`;
	return amount2display;
};

export const REPORT_DATE_TYPE = [
	{ value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
	{ value: 'quarterly', label: 'Quarterly' },
    { value: 'half_yearly', label: 'Half Yearly' },
	{ value: 'custom_date', label: 'Custom Date' }
];
export const CURRENCY_INFO = {
	"PARENT_CURRENCY":"IDR",
	"CURRENT_CURRENCY":"IDR",
	"FROM_CURRENCY":"IDR",
	"TO_CURRENCY":"USD",
	"CONVERSION_RATE":1
};
export const statusList = {'disbursement_done':'Disbursement Done','paid_to_dealer':"Paid to Dealer",'refund_received':"Refund Received",'disbursal_received':"Disbursal Received"};
export const statusArr = [
    { value: 'paid_to_dealer', label: 'Paid to Dealer',lead_source:['finance'] },
    { value: 'refund_received', label: 'Refund Received',lead_source:['finance'] },
	{ value: 'paid_to_dealer', label: 'Paid to Customer',lead_source:['refinance','bikerefinance'] },
    { value: 'refund_received', label: 'Bonus Received',lead_source:['refinance','bikerefinance'] },
    { value: 'disbursal_received', label: 'Disbursal Received',lead_source:['finance'] }
];

// manage menu Permission for Roles 
export const hasAccessPermission = (modulename,page='view') => {
	const userAccess = secureStorage.getItem("userAccess");
	console.log('userAccess==========',userAccess)
	let Permission = userAccess&& userAccess[modulename] && userAccess[modulename][page]=="1"?true:false;
	return Permission;
};
export const maxDATE = (from_date,d=0)=>{
	var date = new Date(from_date);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + d);
	return lastDay
}
export const PRODUCT_TYPE_ALL = [
	{ name: 'UCF', value:"finance", label: 'Used Car Finance' },
	{ name: 'UCRF', value:"refinance", label: 'Used Car Refinance' },
	{ name: 'UBRF', value:"bikerefinance", label: 'Used Bike Refinance' }
];

export const BUSINESS_LINE_FOR_MONTHLY_PAYOUT = [
	{ name: 'UCF', value:"ucf", label: 'Used Car Finance' },
	{ name: 'UCRF', value:"ucrf", label: 'Used Car Refinance' },
	{ name: 'UBRF', value:"ubrf", label: 'Used Bike Refinance' },
	{ name: 'INSURANCE', value: 'insurance', label: 'Insurance' },
	{ name: 'CLASSIFIEDS', value: 'classifieds', label: 'Classifieds' },
	{ name: 'BURSA', value: 'bursa', label: 'Bursa' },
	{ name: 'IF', value: 'if', label: 'IF' }
];

export const PRODUCT_TYPE = ( IS_FAVORITE_APP )
	? PRODUCT_TYPE_ALL.filter(data =>  data.name ==='UCRF' || data.name === 'UCF')
	: (IS_TRIIVCO_APP) ? PRODUCT_TYPE_ALL.filter(data => data.name === 'UCF') : PRODUCT_TYPE_ALL;

export const FAM_ALLOWED_DOC_CATEGORY = [13, 14, 60, 61, 62, 17, 64, 18, 15, 51, 16, 52, 19, 53, 54, 58, 105, 106];
export const UNWIND_ARRAY = function(arr,by) {
	var results = [];
	arr.forEach(function(subArray) {
		let obj = {...subArray};
		delete obj[by];
		if(subArray[by].length){
			subArray[by].forEach(function(item) {
				item = {[by]:item,...obj};
				results.push(item);
			});
		}
		else{
			subArray[by]={};
			results.push(subArray);
		}
	});
	return results;
};
export const PRODUCT_TYPE_OBJECTS_ALL = {
	"finance" : 'UCF',
	"refinance" : 'UCRF',
	"bikerefinance" : 'UBRF'
};

export const PRODUCT_TYPE_OBJECTS_UCRF = {
	"refinance" : 'UCRF',
	"finance" : 'UCF',
};

export const PRODUCT_TYPE_OBJECTS_UCF = {
	"finance": 'UCF'
};

export const PRODUCT_TYPE_OBJECTS = (IS_FAVORITE_APP) ? PRODUCT_TYPE_OBJECTS_UCRF : (IS_TRIIVCO_APP) ? PRODUCT_TYPE_OBJECTS_UCF : PRODUCT_TYPE_OBJECTS_ALL;

export const BANK_LIST_OBJECTS_ALL = {
	"BCA":{acc_number : '0845253451',outbound_acc_number : '0844545556',label:'BCA'},
	"BCA2":{acc_number : '0842335521',outbound_acc_number : '0842335521',label:'BCA'},
	"BCA3":{acc_number : '0842545895',outbound_acc_number : '0842545895',label:'BCA'},
	"Mandiri":{acc_number : '1170010080802',outbound_acc_number : '1170010080802',label:'Mandiri'},
	"CIMB-Niaga":{acc_number : '800157674000',outbound_acc_number : '800157674000',label:'CIMB-Niaga'},
	"Danamon":{acc_number : '3622268310',outbound_acc_number : '3622268310',label:'Danamon'},
	"BCA4":{acc_number : '0356810010',outbound_acc_number : '0356810010',label:'BCA'},
	"BCA5":{acc_number : '0355585200',outbound_acc_number : '0355585200',label:'BCA'},

};
export const BANK_LIST_OBJECTS_UCRF = {
	"BCA4": {acc_number: '0842586796', outbound_acc_number: '0842586796', label: 'BCA KCU Wisma Asia'},
	// "BCA6": {acc_number: '0842586800', outbound_acc_number: '0842586800', label: 'BIRU Konultasi Indonesia'},
	"BCA6": {acc_number: '0842586800', outbound_acc_number: '0842586800', label: 'BCA KCU Wisma Asia'},
};
export const BANK_LIST_OBJECTS = (IS_FAVORITE_APP) ? BANK_LIST_OBJECTS_UCRF : BANK_LIST_OBJECTS_ALL;

export const MAX_ADDITIONAL_REVENUE_SPLIT = 6;
export const DATE_FORMAT_MY = 'MM/yyyy';

export const AMOUNT_FORMAT_TYPE = [
	{ value: 'en', label: 'Indian Accounting Standard System' },
	{ value: 'id', label: 'Indonesian Accounting Standard System' }
]
export const RECONCILATION_STATUS = [
    { value: 'matched', label: 'Matched' },
    { value: 'notmatched', label: 'Not Matched' },
    { value: 'duplicate', label: 'Duplicate' },
    { value: 'manualmatched', label: 'Manual Matched' },
    { value: 'duplicatematched', label: 'Duplicate Matched' },
];
export const RECONCILATION_EXTENDED_DAYS = 2;
export const SPLIT_PAYMENT_TYPE_SOURCE_BASED = {
	finance:[
		{ value: "main_dealer", label: "Dealer Payment" },
		{ value: "split_dealer", label: "Split Payment" },
		{ value: "split_dealer2", label: "Split Payment2" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" },
		{ value: "onhold_dealer", label: "Onhold Dealer Payment" },
		{ value: "bm_fee", label: "BM Fee" },
		{ value: "bro_fee", label: "BRO Fee" }
	],
	refinance:[
		{ value: "main_dealer", label: "Dealer/Agent Incentive" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" },
		{ value: "onhold_agent", label: "Onhold Agent Payment" },
		{ value: "take_over_amount", label: "Take Over Payment" },
		{ value: "customer_payment", label: "Customer Payment" }
	],
	bikerefinance:[
		{ value: "main_dealer", label: "Dealer/Agent Incentive" },
		{ value: "agent_fee", label: "Agent Fee" },
		{ value: "referral_agent", label: "Referral Agent" }
	]
}

let curr_date = new Date();
let STATEMENT_ACTION_MAX_DATE = 31;
export const BANK_STATEMENT_OPERATIONAL_PERIOD = {
    minimum_date: new Date(curr_date.getFullYear(), curr_date.getMonth(), 1, 0, 0, 0 ),
    maximum_date: new Date(curr_date.getFullYear(), curr_date.getMonth(), STATEMENT_ACTION_MAX_DATE, 23, 59, 59),
};
export const TAX_VALUE = 1.09;/// 1.08
export const TAX_PPN = 11;/// 11%

export const OTHER_PAYMENT_TRANS_TYPE = [
	{ value: 1, label: 'Interest Amount' },
	{ value: 2, label: 'Other Payment' },
	{ value: 3, label: 'Reward' },
	{ value: 4, label: 'Sweep-out Transaction' },
	{ value: 5, label: 'Wrong Transfer' },
	{ value: 6, label: 'Excess Amount' }
];

// Radis
export const IS_REDIS = false;
export const SHOW_CAPTCHA = false  // process.env.REACT_APP_SHOW_CAPTCHA;
// console.log(process.env)

export const MONTHS = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
];
export const PO_DOC_CATEGORY = [13,63,39,7,59];
export const BKPB_DOC_CATEGORY = [68,66,65];
export const PO_BKPB_DOC_CATEGORY = [13,63,39,7,59,68,66,65];

export const FINANCIER_IDS_TO_EXCLUDE_WALLET=[17];

export const XENDIT_STATUS_TYPE = [
	{ value : "1",  label : "In Progress" },
	{ value : "2",  label : "Fail" },
	{ value : "3",  label : "Success" }
];
export const PAYMENT_SOURCE_TYPE = {
	"carbay" : 'Carbay',
	"merah" : 'Merah',
	"manual_carbay": 'Manual Carbay',
	"manual_merah": 'Manual Merah',
	"others" : 'Others',
	"pt_biru" : 'PT-Biru'
};

export const SERVER_YEAR = [
	{ value : "2020",  label : "2020" },
	{ value : "2021",  label : "2021" },
	{ value : "2022",  label : "2022" },
	{ value : "2023",  label : "2023" },
	{ value : "2024",  label : "2024" },
	{ value : "2025",  label : "2025" },
	{ value : "2026",  label : "2026" },
	{ value : "2027",  label : "2027" },
	{ value : "2028",  label : "2028" },
	{ value : "2029",  label : "2029" },
	{ value : "2030",  label : "2030" },
]

export const SERVER_MONTH = [
	{ value : "1",  label : "January" },
	{ value : "2",  label : "February" },
	{ value : "3",  label : "March" },
	{ value : "4",  label : "April" },
	{ value : "5",  label : "May" },
	{ value : "6",  label : "June" },
	{ value : "7",  label : "July" },
	{ value : "8",  label : "August" },
	{ value : "9",  label : "September" },
	{ value : "10",  label : "October" },
	{ value : "11",  label : "November" },
	{ value : "12",  label : "December" }
];


export const SALES_TASK_AGEING_DAYS = [
	{ id: 1, value: "3-5", label: "3-5 Days" },
	{ id: 2, value: "6-10", label: "6-10 Days" },
	{ id: 3, value: "11-30", label: "11-30 Days" },
	{ id: 4, value: "> 30", label: "> 30 Days" },
];

export const SALES_TASK_USER_ROLES = [
	{ id: 14, value: "Staff Coll", key: "Staff Coll" },
	{ id: 15, value: "Staff QA", key: "Staff QA" },
	{ id: 16, value: "TL Recon", key: "TL Recon" },
	{ id: 17, value: "TL Coll", key: "TL Coll" },
	{ id: 18, value: "Ast. Man", key: "Ast. Man" },
	{ id: 19, value: "HoAS", key: "HoAS" },
];
export const SERVICE_FEE_ALLOWED_FINANCER = [17, 6, 12];

export const REDIRECT_URLS = {
	"beta" : "https://pre-sso.oto.com/google?redirect=",
	"preprod" : "https://pre-sso.oto.com/google?redirect=",
	"local" : "http://sso-beta.oto.com/google?redirect=",
	"prod" : "https://sso.oto.com/google?redirect="
}

export const TAKE_OVER_TYPE = [
	{ value : "0",  label : "No" },
	{ value : "1",  label : "Yes" }
];

export const REDIRECT_URL = REDIRECT_URLS[process.env.REACT_APP_ENV];
export const SHOW_EMAIL_LOGIN_APP_TYPE = ['local','beta','preprod']
export const SFA_ROLE_NAME_OTO = [
	{ value: 51, label:'RBH' },
	{ value: 58, label:'ZM' },
	{ value: 56, label:'AM' },
	{ value: 52, label:'BM' },
	{ value: 53, label:'AGENT' },
	{ value: 54, label:'BRO' },
	{ value: 57, label:'ARO' },
	{ value: 50, label:'VP' },
	{ value: 59, label:'ARCO' }
];

export const RISK_CLASSIFICATION_QUESTIONS_MAPPING = {
	GREEN: ['BM_CALLING'],
	YELLOW: ['BM_CALLING', 'GIO_TAGGING_PICS'],
	ORANGE: ['BM_CALLING', 'GIO_TAGGING_PICS', 'CUSTOMER_CALLING'],
	RED: ['BM_CALLING', 'GIO_TAGGING_PICS', 'CUSTOMER_CALLING', 'AM_APPROVAL'],
};

export const QUESTION_TYPES = {
	YES_NO: 'yes_no',
	TEXT: 'text',
	TEXTARIA: 'textaria',
	FILE: 'file',
};

export const RISK_CLASSIFICATION_QUESTIONS = {
    BM_CALLING: [
        {
            question_id: 1,
            question_key: 'bm_calling_done',
            question: 'Have you done BM Calling?',
            type: QUESTION_TYPES.YES_NO,
        },
        {
            question_id: 2,
            question_key: 'bm_calling_result',
            question: 'Were the results of BM Calling positive?',
            type: QUESTION_TYPES.YES_NO,
            condition: (formData) => formData[1] === 'yes',
        },
        {
            question_id: 3,
            question_key: 'bm_calling_remarks',
            question: 'BM Calling Remarks',
            type: QUESTION_TYPES.TEXTARIA,
        },
    ],
    GIO_TAGGING_PICS: [
        {
            question_id: 4,
            question_key: 'car_pics_clear',
            question: 'Are the car pictures clear?',
            type: QUESTION_TYPES.YES_NO,
        },
        {
            question_id: 5,
            question_key: 'car_good_state',
            question: 'Is the car in a good state?',
            type: QUESTION_TYPES.YES_NO,
			condition: (formData) => formData[4] === 'yes'
        },
        {
            question_id: 6,
            question_key: 'car_lat',
            question: 'Latitude',
            type: QUESTION_TYPES.TEXT,
            readonly: true,
        },
        {
            question_id: 7,
            question_key: 'car_long',
            question: 'Longitude',
            type: QUESTION_TYPES.TEXT,
            readonly: true,
        },
        {
            question_id: 8,
            question_key: 'car_displacement',
            question: 'Disp. from Dealer (in KM)',
            type: QUESTION_TYPES.TEXT,
            readonly: true,
        },
        {
            question_id: 9,
            question_key: 'geo_location_proceed',
            question: 'Is it okay to proceed with the shared geo-location?',
            type: QUESTION_TYPES.YES_NO,
            condition: (formData) =>
                formData["6"] &&
                formData["7"] &&
                formData["8"],
        },
        {
            question_id: 10,
            question_key: 'car_pics_remarks',
            question: 'Car Pics/Location Remarks',
            type: QUESTION_TYPES.TEXTARIA,
        },
    ],
    CUSTOMER_CALLING: [
        {
            question_id: 11,
            question_key: 'customer_received_car',
            question: 'Has the customer received the car?',
            type: QUESTION_TYPES.YES_NO,
        },
        {
            question_id: 12,
            question_key: 'customer_car_good',
            question: 'Is the car in good condition?',
            type: QUESTION_TYPES.YES_NO,
            condition: (formData) => formData['11'] === 'yes',
        },
        {
            question_id: 13,
            question_key: 'customer_calling_remarks',
            question: 'Customer Calling Remarks',
            type: QUESTION_TYPES.TEXTARIA,
        },
    ],
    AM_APPROVAL: [
        {
            question_id: 14,
            question_key: 'am_approval_doc',
            question: 'Upload AM Approval Doc',
            type: QUESTION_TYPES.FILE,
        },
        {
            question_id: 15,
            question_key: 'am_approval_remarks',
            question: 'AM Approval Remarks',
            type: QUESTION_TYPES.TEXTARIA,
        },
    ],
};


export const TDP_FINANCER_IDS = 2008;

export const FID_DOC_CAT = process.env.REACT_APP_GETWAY_API || 74;
